/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';
import { Link } from 'gatsby';

export const MenuContainer = styled.nav`
  grid-area: Header;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  transition: background-color 200ms ease-in-out;
`;

export const MenuBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 101;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: auto;
  }
`;

export const LogoWrapper = styled(ItemWrapper)`
  width: auto;
  padding: 1rem 0 1rem 0;

  svg {
    width: auto;
    height: 1.2rem;

    path {
      fill: ${({ theme }) => theme.color.foreground};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xxsMax}) {
      height: 1rem;
    }
  }
  &.menuOpen-light {
    svg path {
      fill: ${({ theme }) => theme.backgroundColors.dark};
    }
  }

  &.menuOpen-dark {
    svg path {
      fill: ${({ theme }) => theme.backgroundColors.light};
    }
  }

  &.menuOpen-grey {
    svg path {
      fill: ${({ theme }) => theme.backgroundColors.dark};
    }
  }
`;

export const BurgerWrapper = styled(ItemWrapper)`
  -webkit-appearance: none;
  max-width: 2rem;
  position: relative;

  svg {
    min-width: 2rem;

    path {
      fill: ${({ theme }) => theme.color.foreground};
    }
  }

  &:focus {
    outline: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -10px;
  }

  &.menuOpen-light {
    svg path {
      fill: ${({ theme }) => theme.backgroundColors.dark};
    }
  }

  &.menuOpen-dark {
    svg path {
      fill: ${({ theme }) => theme.backgroundColors.light};
    }
  }

  &.menuOpen-grey {
    svg path {
      fill: ${({ theme }) => theme.backgroundColors.dark};
    }
  }
`;

export const NavCategoryDivider = styled.div`
  width: 2rem;
  height: 0.1rem;
  margin: 3rem 0;
  opacity: 0.5;

  @media (max-width: ${({ theme }) =>
    theme.breakpoints.smMax}) and (orientation: landscape) {
    display: none;
  }
`;

export const NavigationContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 0px;
  max-height: 0vh;
  display: none;
  opacity: 0.85;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: max-height ${({ theme }) => theme.easings.themeChange};
  will-change: max-height;

  &.menu-open {
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    display: flex;
  }

  &.bg-light {
    background-color: ${({ theme }) => theme.backgroundColors.light};
    color: ${({ theme }) => theme.backgroundColors.dark};

    ${NavCategoryDivider} {
      background-color: ${({ theme }) => theme.backgroundColors.dark};
    }
  }

  &.bg-dark {
    background-color: ${({ theme }) => theme.backgroundColors.dark};
    color: ${({ theme }) => theme.backgroundColors.light};

    ${NavCategoryDivider} {
      background-color: ${({ theme }) => theme.backgroundColors.light};
    }
  }

  &.bg-grey {
    background-color: ${({ theme }) => theme.backgroundColors.grey};
    color: ${({ theme }) => theme.backgroundColors.dark};

    ${NavCategoryDivider} {
      background-color: ${({ theme }) => theme.backgroundColors.dark};
    }
  }

  /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    opacity: 0.7;
  } */
`;

export const LinkList = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LinkEntry = styled(Link)`
  margin: 0;
  padding: 0;
  position: relative;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  color: inherit;

  &.link-active {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  &:after {
    border-bottom: 1px solid ${({ theme }) => theme.color.foreground};
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 1px;
    width: 100%;

    will-change: transform;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &:hover {
    &::after {
      will-change: transform;
      transform-origin: left;
      transform: scaleX(1);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MainList = styled(LinkList)`
  @media (max-width: ${({ theme }) =>
    theme.breakpoints.smMax}) and (orientation: landscape) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 6rem;
    justify-content: center;
  }
`;

export const MainLink = styled(LinkEntry)`
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  margin-bottom: 2rem;
  letter-spacing: 0.2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.xsMax}) {
    font-size: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsMax}) {
    font-size: 2.2rem;
  }

  @media (max-width: ${({ theme }) =>
    theme.breakpoints.smMax}) and (orientation: landscape) {
    font-size: 2.5rem;
    margin: 0 2rem;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 2rem;
    }
  }
`;

export const MetaList = styled(LinkList)`
  @media (max-width: ${({ theme }) =>
    theme.breakpoints.smMax}) and (orientation: landscape) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 6rem;
    justify-content: center;
  }
`;

export const MetaLink = styled(LinkEntry)`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  margin-bottom: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.xxsMax}) {
    font-size: 1.3rem;
  }

  @media (max-width: ${({ theme }) =>
    theme.breakpoints.smMax}) and (orientation: landscape) {
    margin: 0 2rem;
    margin-top: 1.5rem;
  }
`;

export const NavListWrapper = styled.div`
  overflow-y: hidden;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${({ theme }) =>
    theme.breakpoints.smMax}) and (orientation: landscape) {
    margin-top: 8rem;
    margin-bottom: 5rem;
  }
`;
