import React from 'react';

const CloseIcon = ({ fill }) => (
  <svg viewBox="0 0 14.849 14.849" id="menu-close">
    <path
      fill={fill}
      fillRule="nonzero"
      className="fill-fade"
      d="m0 14.142 14.142-14.142.707.707-14.142 14.142z"
    />
    <path
      fill={fill}
      fillRule="nonzero"
      className="fill-fade"
      d="m.707 0 14.142 14.142-.707.707-14.142-14.142z"
    />
  </svg>
);

export default CloseIcon;
