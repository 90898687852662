/* eslint-env node,react */
import ogImageLandscape from '../../static/images/roschlaubcom_OG_1200x630_moz.jpg';
import ogImageSquare from '../../static/images/roschlaubcom_OG_1080x1080_moz.jpg';

// publicPath is needed for absolute paths in og tag...
const publicPath = process.env.GATSBY_SITE_BASE_URL;

export const FALLBACK_OG_IMAGES = [
  {
    url: `${publicPath}${ogImageLandscape}`,
    width: 1200,
    height: 630,
  },
  {
    url: `${publicPath}${ogImageSquare}`,
    width: 1080,
    height: 1080,
  },
];

export const buildOpenGraphImages = (images) => {
  try {
    const { horizontal, square } = images;
    return [
      {
        url: horizontal.src,
        width: horizontal.width,
        height: horizontal.height,
      },
      {
        url: square.src,
        width: square.width,
        height: square.height,
      },
    ];
  } catch (error) {
    return FALLBACK_OG_IMAGES;
  }
};
