import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

// components
import {
  MenuContainer,
  MenuBar,
  LogoWrapper,
  BurgerWrapper,
  NavigationContainer,
  MainList,
  MainLink,
  MetaList,
  MetaLink,
  NavCategoryDivider,
  NavListWrapper,
} from './styles';
import { BurgerIcon, CloseIcon, Logo } from '../Svg';

const MenuQuery = graphql`
  query MenuQuery {
    contentfulNavigation(role: { eq: "Primary" }) {
      __typename
      id
      backgroundColor
      mainEntries {
        __typename
        id
        title
        slug
      }
      metaEntries {
        __typename
        id
        title
        slug
      }
    }
  }
`;

const Menu = () => {
  const data = useStaticQuery(MenuQuery);

  const {
    contentfulNavigation: { backgroundColor, mainEntries, metaEntries },
  } = data;

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <MenuContainer>
      <MenuBar>
        <Link to="/" title="Home">
          <LogoWrapper
            className={`${
              menuOpen && 'menuOpen-'
            }${backgroundColor.toLowerCase()}`}
          >
            <Logo />
          </LogoWrapper>
        </Link>
        <BurgerWrapper
          as="button"
          role="button"
          aria-label="Menu"
          tabIndex={0}
          onClick={() => setMenuOpen(!menuOpen)}
          className={`${
            menuOpen && 'menuOpen-'
          }${backgroundColor.toLowerCase()}`}
        >
          {!menuOpen && <BurgerIcon />}
          {menuOpen && <CloseIcon />}
        </BurgerWrapper>
      </MenuBar>
      <NavigationContainer
        className={`${
          menuOpen && 'menu-open'
        } bg-${backgroundColor.toLowerCase()}`}
      >
        <NavListWrapper>
          <MainList>
            {mainEntries.map(({ slug, title, id }) => (
              <MainLink
                to={`/${slug}`}
                key={id}
                activeClassName="link-active"
                aria-label={title}
                onClick={() => setMenuOpen(false)}
              >
                {title}
              </MainLink>
            ))}
          </MainList>
          <NavCategoryDivider />
          <MetaList>
            {metaEntries.map(({ slug, title, id }) => (
              <MetaLink
                to={`/${slug}`}
                key={id}
                activeClassName="link-active"
                aria-label={title}
                onClick={() => setMenuOpen(false)}
              >
                {title}
              </MetaLink>
            ))}
          </MetaList>
        </NavListWrapper>
      </NavigationContainer>
    </MenuContainer>
  );
};
export default Menu;
