import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useSwiperState } from '../../context/SwiperState';

// Styles
import { base as GlobalStyles } from '../../styles';
import { lightTheme, darkTheme } from '../../styles/theme';
import { Main, BackgroundImage } from './styles';

const Layout = ({ children, className, backgroundImage }) => {
  const [{ currentTheme }, dispatch] = useSwiperState();
  const theme = currentTheme === 'light' ? lightTheme : darkTheme;

  useEffect(() => {
    if (backgroundImage) {
      dispatch({ type: 'SET_CURRENT_THEME', value: 'dark' });
    }
  }, [backgroundImage]);

  return (
    <>
      <GlobalStyles theme={theme} />
      <ThemeProvider theme={theme}>
        <Main className={className} hasBackground={backgroundImage}>
          {children}
          {backgroundImage && (
            <BackgroundImage
              url={backgroundImage && backgroundImage.fixed.src}
              alt=""
            />
          )}
        </Main>
      </ThemeProvider>
    </>
  );
};

export default Layout;
