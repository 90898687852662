/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';

export const ProgressNumber = styled.span`
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  margin: 2rem 0;
  color: ${({ muted, theme }) =>
    muted ? theme.color.accentMid : theme.color.accentStrong};
  user-select: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.xsMax}) {
    margin: 0;
    font-size: 1.4rem;
  }
`;

export const SliderTrack = styled.div`
  position: relative;
  display: inline-block;
  user-select: none;
  background-color: ${({ theme }) => theme.color.hover};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: pointer;

  &.axis-y {
    width: 1px;
    height: 150px;
  }

  &.axis-x {
    width: 200px;
    height: 2px;
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    left: -3px;
    right: -3px;
  }
`;

export const SliderTrackActive = styled.div`
  position: absolute;
  user-select: none;
  background-color: ${({ theme }) => theme.color.accentMid};

  &.axis-y {
    left: 0;
    width: 100%;
    transition: height ease-in-out 500ms;
    will-change: height;
  }

  &.axis-x {
    top: 0;
    height: 100%;
    /* transition: width linear 200ms;
    will-change: width; */
  }
`;

export const SliderThumb = styled.div`
  position: relative;
  display: block;
  content: '';
  background-color: ${({ theme }) => theme.color.accentStrong};
  user-select: none;
  cursor: grab;

  &.axis-y {
    width: 4px;
    height: 20px;
  }

  &.axis-x {
    width: 10px;
    height: 4px;
  }

  &:after {
    content: '';
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
  }
`;

export const SliderThumbController = styled.div`
  &.axis-y {
    transition: top ease-in-out 500ms;
    will-change: top;
  }

  /* &.axis-x {
    transition: left linear 200ms;
    will-change: left;
  } */
`;

export const MobileNumberDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.foreground};
  user-select: none;
  margin: 1rem 0;
`;
