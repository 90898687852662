import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';

export const GatsbyImage = styled(Img)`
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;

  figure {
    margin: 0;
    padding: 0;
    display: block;
  }

  img {
    pointer-events: none;
    user-select: none;
  }
`;
