import React from 'react';

const BurgerIcon = ({ fill }) => (
  <svg viewBox="0 0 20 16" id="menu-burger">
    <path
      fill={fill}
      fillRule="nonzero"
      className="fill-fade"
      d="M20 14v1.5H6V14h14zm0-7v1.5H0V7h20zm0-7v1.5H8V0h12z"
    />
  </svg>
);

export default BurgerIcon;
