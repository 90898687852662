import styled from 'styled-components';

export const Grid = styled.section`
  height: 100%;
  display: grid;
  position: relative;
  z-index: 1;
  grid-template-columns: 7.5rem 1fr 7.5rem;
  grid-template-rows: 7.5rem 1fr 7.5rem;
  grid-template-areas:
    'Header Header Header'
    'Category Slider Progress'
    'Footer Footer Footer';

  @media (max-width: ${({ theme }) => theme.breakpoints.xsMax}) {
    grid-template-columns: 2.5rem 1fr 2.5rem;
    grid-template-rows: 8rem 1fr 14rem;
  }
`;

export const Slides = styled.div`
  grid-area: Slider;
  overflow: hidden;

  .swiper-container {
    height: 100%;
  }
`;

export const SideColumn = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CategoryColumn = styled(SideColumn)`
  grid-area: Category;
`;

export const ProgressColumn = styled(SideColumn)`
  grid-area: Progress;
`;

export const CategoryHeadline = styled.h2`
  color: ${({ theme }) => theme.color.accentMid};
  font-weight: ${({ theme }) => theme.font.weight.book};
  font-size: ${({ theme }) => theme.font.size.slideCategory};
  letter-spacing: 0.24rem;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  text-orientation: sideways;
  transform: rotate(180deg);
  user-select: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.xsMax}) {
    transform: rotate(0deg);
    writing-mode: initial;
    text-orientation: initial;
    font-size: ${({ isHome }) => (isHome ? '1.8rem' : '1.4rem')};
  }
`;

export const SlideWrapper = styled.div`
  .react-player > [data-vimeo-initialized='true'] {
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 70%;
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: all;
      z-index: 10;
      /* background: red;
      opacity: 0.25; */
      @media (max-width: ${({ theme }) => theme.breakpoints.xsMax}) {
        height: 22%;
      }
      @media (max-width: ${({ theme }) => theme.breakpoints.smMax}) {
        height: 22%;
      }
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      pointer-events: all;
      z-index: 10;
      /* background: red;
      opacity: 0.25; */
      @media (max-width: ${({ theme }) => theme.breakpoints.smMax}) {
        height: 20%;
      }
    }
  }
`;

export const ImageWrapper = styled.figure`
  position: absolute;
  background: rgb(105, 100, 100);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  object-fit: contain;
  /* object-position: center center; */
  pointer-events: none;
`;
