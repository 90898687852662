/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import {
  SliderTrack,
  SliderTrackActive,
  SliderThumb,
  SliderThumbController,
} from './styles';

function getClientPosition(e) {
  const { touches } = e;

  if (touches && touches.length) {
    const finger = touches[0];
    return {
      x: finger.clientX,
      y: finger.clientY,
    };
  }

  return {
    x: e.clientX,
    y: e.clientY,
  };
}

const Slider = ({
  disabled,
  axis,
  x,
  y,
  xmin,
  xmax,
  ymin,
  ymax,
  xstep,
  ystep,
  onChange,
  onDragStart,
  onDragEnd,
  onClick,
  xreverse,
  yreverse,
  styles: customStyles,
  ...props
}) => {
  const container = useRef(null);
  const handle = useRef(null);
  const start = useRef({});
  const offset = useRef({});

  function getPosition() {
    let top = ((y - ymin) / (ymax - ymin)) * 100;
    let left = ((x - xmin) / (xmax - xmin)) * 100;

    if (top > 100) top = 100;
    if (top < 0) top = 0;
    if (axis === 'x') top = 0;

    if (left > 100) left = 100;
    if (left < 0) left = 0;
    if (axis === 'y') left = 0;

    return { top, left };
  }

  function change({ top, left }) {
    if (!onChange) return;

    const { width, height } = container.current.getBoundingClientRect();
    let dx = 0;
    let dy = 0;

    if (left < 0) left = 0;
    if (left > width) left = width;
    if (top < 0) top = 0;
    if (top > height) top = height;

    if (axis === 'x' || axis === 'xy') {
      dx = (left / width) * (xmax - xmin);
    }

    if (axis === 'y' || axis === 'xy') {
      dy = (top / height) * (ymax - ymin);
    }

    const xTemp = (dx !== 0 ? parseInt(dx / xstep, 10) * xstep : 0) + xmin;
    const yTemp = (dy !== 0 ? parseInt(dy / ystep, 10) * ystep : 0) + ymin;

    onChange({
      x: xreverse ? xmax - xTemp + xmin : xTemp,
      y: yreverse ? ymax - yTemp + ymin : yTemp,
    });
  }

  function getPos(e) {
    const clientPos = getClientPosition(e);
    const left = clientPos.x + start.current.x - offset.current.x;
    const top = clientPos.y + start.current.y - offset.current.y;

    return { left, top };
  }

  function handleDrag(e) {
    if (disabled) return;

    e.preventDefault();
    change(getPos(e));
  }

  function handleDragEnd(e) {
    if (disabled) return;

    e.preventDefault();
    document.removeEventListener('mousemove', handleDrag);
    document.removeEventListener('mouseup', handleDragEnd);

    document.removeEventListener('touchmove', handleDrag, {
      passive: false,
    });
    document.removeEventListener('touchend', handleDragEnd);
    document.removeEventListener('touchcancel', handleDragEnd);

    if (onDragEnd) {
      onDragEnd(e);
    }
  }

  function handleMouseDown(e) {
    if (disabled) return;

    e.preventDefault();
    const dom = handle.current;
    const clientPos = getClientPosition(e);

    start.current = {
      x: dom.offsetLeft,
      y: dom.offsetTop,
    };

    offset.current = {
      x: clientPos.x,
      y: clientPos.y,
    };

    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchmove', handleDrag, { passive: false });
    document.addEventListener('touchend', handleDragEnd);
    document.addEventListener('touchcancel', handleDragEnd);

    if (onDragStart) {
      onDragStart(e);
    }
  }

  function handleClick(e) {
    if (disabled) return;

    const clientPos = getClientPosition(e);
    const rect = container.current.getBoundingClientRect();

    change({
      left: clientPos.x - rect.left,
      top: clientPos.y - rect.top,
    });

    if (onClick) onClick(e);
  }

  const pos = getPosition();
  const valueStyle = {};
  if (axis === 'x') valueStyle.width = `${pos.left}%`;
  if (axis === 'y') valueStyle.height = `${pos.top}%`;
  if (xreverse) valueStyle.left = `${100 - pos.left}%`;
  if (yreverse) valueStyle.top = `${100 - pos.top}%`;

  const handleStyle = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: xreverse ? `${100 - pos.left}%` : `${pos.left}%`,
    top: yreverse ? `${100 - pos.top}%` : `${pos.top}%`,
  };

  if (axis === 'x') {
    handleStyle.top = '50%';
  } else if (axis === 'y') {
    handleStyle.left = '50%';
  }

  return (
    <SliderTrack
      {...props}
      ref={container}
      onClick={handleClick}
      className={`axis-${axis} bg-fade`}
    >
      <SliderTrackActive
        style={valueStyle}
        className={`axis-${axis} bg-fade`}
      />
      <SliderThumbController
        ref={handle}
        style={handleStyle}
        className={`axis-${axis}`}
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        <SliderThumb className={`axis-${axis} bg-fade`} />
      </SliderThumbController>
    </SliderTrack>
  );
};

Slider.defaultProps = {
  disabled: false,
  axis: 'x',
  x: 50,
  xmin: 0,
  xmax: 100,
  y: 50,
  ymin: 0,
  ymax: 100,
  xstep: 1,
  ystep: 1,
  xreverse: false,
  yreverse: false,
  styles: {},
};

export default Slider;
