import styled from 'styled-components';

export const FooterSection = styled.footer`
  grid-area: Footer;
  grid-column: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.xsMax}) {
    align-items: flex-start;
    margin-top: 2.5rem;
  }
`;

export const SlideTitle = styled.h1`
  margin-right: 2rem;
  display: inline-block;
  color: ${({ theme }) => theme.color.accentStrong};
  font-size: 1.3rem;
  letter-spacing: 0.03rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.4;

  @media (max-width: ${({ theme }) => theme.breakpoints.xsMax}) {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }
`;

export const SlideSubtitle = styled.h3`
  color: ${({ theme }) => theme.color.accentMid};
  font-size: ${({ theme }) => theme.font.size.slideSubtitle};
  letter-spacing: 0.03rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.4;
  margin-top: 3px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xsMax}) {
    margin-bottom: 0.5rem;
  }
`;

export const HeadlineWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.xsMax}) {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 2.5rem;
  }
`;

export const SlideCategory = styled.h1`
  margin-right: 0.5rem;
  display: inline-block;
  color: ${({ theme }) => theme.color.accentStrong};
  font-size: 1.3rem;
  letter-spacing: 0.03rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  user-select: none;
  position: relative;
  cursor: pointer;

  &:after {
    border-bottom: 1px solid ${({ theme }) => theme.color.accentMid};
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 1px;
    width: 100%;

    will-change: transform;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &:hover {
    &::after {
      will-change: transform;
      transform-origin: left;
      transform: scaleX(1);
    }
  }
`;

export const SlideCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
`;

export const MobileSlideNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileCategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MobileHomeCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
