// Array of supported sizes
const sizes = [
  160,
  320,
  480,
  640,
  800,
  960,
  1024,
  1200,
  1440,
  1600,
  1850,
  2048,
];

// Max size in contentful
const cfMax = 4000;

/**
 * Compute src and srcset based on sizes for the given format & ratio
 *
 * @param {Object} file File object
 * @param {String} format Can be webp, jpg or png
 * @param {Array}  additionalSizes adds some additional sizes
 * @returns {Object} Object containing src & srcset
 */
const getSources = (file, additionalSizes, max, format) => {
  const { url, details, contentType } = file || {};
  const { image } = details || {};
  const { width, height } = image || {};
  // Creates an sorted array from sizes & additionalSizes
  const combinedSizes = [
    ...new Set([max, ...sizes, ...(additionalSizes || [])]),
  ]
    .sort((a, b) => a - b)
    .filter((v) => v);
  const widths = [...combinedSizes.filter((w) => w < width), width];
  const formatString = format ? `&fm=${format}` : '';
  const additional =
    (!format && /\/jpe?g/.test(contentType)) || format === 'jpg'
      ? '&fl=progressive'
      : '';
  const sources = [...widths].reduce((result, w) => {
    const h = Math.ceil(w / (width / height));
    if (max && w > max) {
      return result;
    }

    if (w >= cfMax || h >= cfMax) {
      return result;
    }

    if (!url) {
      return result;
    }

    return {
      ...result,
      [w]: `${`https:${url.replace(
        /^https?:/,
        ''
      )}`}?fit=fill&w=${w}&h=${h}${formatString}${additional}&q=60`,
    };
  }, {});

  const [min] = Object.values(sources) || [];
  const srcset = Object.keys(sources).reduce(
    (res, w) => [...res, `${sources[w]} ${w}w`],
    []
  );

  return [min, srcset.join(',\n')];
};

/**
 * Create fluid props required for gatsby-image based on contentful asset object
 *
 * @param {Object} file Contentful asset data
 * @param {array} additionalSizes Specify additional sizes
 * @returns Fluid image object {src, srcSet, srcWebp, srcSetWebp, sizes, aspectRatio}
 */
export const getFluidProps = (file, customSizes, maxWidth) => {
  const { details } = file || {};
  const { image } = details || {};
  const { width, height } = image || {};

  // Extract additional sizes from sizes attribute so we can query exactly the image required
  const additionalSizes = (customSizes || '').split(',').flatMap((q) => {
    const [, w] = /(\d+)px\s*$/.exec(q || '') || [];
    return w ? [w, w * 2] : [];
  });

  const max = parseInt(maxWidth, 10) || 0;
  const sizesString =
    customSizes || `(max-width: ${width}px) 100vw, ${width}px`;
  const aspectRatio = Math.round((width / height) * 10) / 10;
  const [src, srcSet] = getSources(file, additionalSizes, max);
  const [srcWebp, srcSetWebp] = getSources(file, additionalSizes, max, 'webp');

  return {
    src,
    srcSet,
    srcWebp,
    srcSetWebp,
    sizes: sizesString,
    aspectRatio,
  };
};
