import React from 'react';
import { getFluidProps } from '../../helpers/asset';
import { GatsbyImage } from './styles';

const ImageSlide = (data) => {
  // console.log('Image Slide Data:', data);

  const {
    image: { file },
  } = data;
  // file, sizes, maxWidth
  const fluid = getFluidProps(file);

  return (
    <GatsbyImage
      fluid={fluid}
      objectFit={data.imageFit.toLowerCase()}
      Tag="figure"
      alt={data.title}
    />
  );
};

export default ImageSlide;
