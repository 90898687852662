import React, { useState } from 'react';
import ReactPlayer from 'react-player';
// import { useInView } from 'react-intersection-observer';
// import { useSwiperState } from '../../context/SwiperState';

const VideoSlide = (data) => {
  // console.log('Video Slide Data:', data);

  // const [state, dispatch] = useSwiperState();
  // const { videoPlaying, videoMuted, videoReady } = state;

  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [ready, setReady] = useState(false);
  // const [playerProgress, setPlayerProgress] = useState({
  //   playedSeconds: 0,
  //   played: 0,
  // });

  // const playerRef = useRef();
  // if (playerRef && playerRef.current) {
  //   window.player = playerRef.current;
  // }

  // const stateConsole = {
  //   playing,
  //   muted,
  //   ready,
  //   playerProgress,
  //   playerRef,
  // };
  // console.table(stateConsole);

  return (
    <ReactPlayer
      // ref={playerRef}
      className="react-player"
      width="100%"
      height="100%"
      url={`https://vimeo.com/${data.vimeoId}`}
      playing={playing}
      muted={muted}
      volume={1}
      light={data.poster ? `${data.poster.fixed.src}&fl=progressive` : true}
      controls
      // onPlay={() => dispatch({ type: 'SET_CURRENT_PLAYER', value: playerRef })}
      onReady={() => {
        setReady(true);
        setPlaying(true);
        // dispatch({ type: 'SET_CURRENT_PLAYER', value: playerRef });
        // dispatch({
        //   type: 'SET_VIDEO_READY',
        //   value: true,
        // });
      }}
      // onProgress={({ playedSeconds, played }) =>
      //   setPlayerProgress((prev) => ({ ...prev, playedSeconds, played }))
      // }
      // onDuration={(duration) =>
      //   dispatch({ type: 'SET_VIDEO_DURATION', value: duration })
      // }
      // onEnded={() =>
      //   dispatch({
      //     type: 'SET_VIDEO_PROGRESS',
      //     value: { playedSeconds: 0, played: 0 },
      //   })
      // }
    />
  );
};

export default VideoSlide;
