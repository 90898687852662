/* eslint-disable dot-notation */
import React, { useEffect, useState } from 'react';
import CustomSwiper from 'react-id-swiper/lib/ReactIdSwiper.custom';
import { Swiper, Keyboard, Mousewheel } from 'swiper/js/swiper.esm';
import 'swiper/css/swiper.css';
import { useLocation } from '@reach/router';

// Components
import { useSwiperState } from '../../context/SwiperState';
import { ImageSlide, VideoSlide } from '../Slide';
import { Menu } from '../Menu';
import { BottomBar } from '../BottomBar';
import { ProgressSlider } from '../ProgressSlider';
import { useMediaQuery } from '../../hooks';

import {
  Grid,
  CategoryColumn,
  ProgressColumn,
  CategoryHeadline,
  Slides,
  SlideWrapper,
} from './styles';
import { theme } from '../../styles';

const SlideView = ({ data, isHome }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [requireSliding, setRequireSliding] = useState(null);

  const isMobile = useMediaQuery(
    [`(max-width: ${theme.breakpoints.xsMax})`],
    [true],
    false
  );

  let slides = [];
  let categoryTitle;

  if (isHome) {
    slides = data;
    categoryTitle = slides[currentSlide].category[0].title;
  } else {
    slides = data.slides;
    categoryTitle = data.title;
  }

  // console.log('SlideView Data:', data);

  const totalSlides = (slides || []).length;

  const loc = useLocation();

  const [state, dispatch] = useSwiperState();
  const { currentSwiper: swiper } = state;

  useEffect(() => {
    dispatch({
      type: 'SET_CURRENT_THEME',
      value: (slides[currentSlide].backgroundColor || '').toLowerCase(),
    });
  }, [slides, currentSlide]);

  useEffect(() => {
    if (!loc.search) return;
    const query = new URLSearchParams(loc.search).get('id');
    const targetIndex = slides.findIndex((slide) => slide.id === query);
    setRequireSliding(targetIndex);
  }, [slides, loc.search]);

  useEffect(() => {
    if (swiper !== null) {
      window.swiper = swiper;
      if (requireSliding) {
        swiper.slideTo(requireSliding, 1000);
        setCurrentSlide(requireSliding);
        setRequireSliding(null);
      }
      swiper.on('slideChange', () => {
        const currIndex = swiper.activeIndex;
        setCurrentSlide(currIndex);
      });
      // swiper.on('slideChangeTransitionEnd', () => {
      //   dispatch({ type: 'RESET_VIDEO' });
      // });
      // swiper.on('beforeDestroy', () => {
      //   dispatch({ type: 'SET_CURRENT_SWIPER', value: null });
      // });
    }
  }, [swiper, requireSliding]);

  const params = {
    Swiper,
    modules: [Keyboard, Mousewheel],
    slidesPerView: 1,
    mousewheel: true,
    keyboard: true,
    grabCursor: true,
    direction: 'vertical',
    speed: 1000,
    getSwiper: (instance) =>
      dispatch({ type: 'SET_CURRENT_SWIPER', value: instance }),
  };

  return (
    <>
      <Grid>
        <Menu />

        <CategoryColumn>
          {!isMobile && (
            <CategoryHeadline className="color-fade">
              {categoryTitle}
            </CategoryHeadline>
          )}
        </CategoryColumn>

        <Slides>
          <CustomSwiper {...params}>
            {slides.map((slide) => (
              <SlideWrapper key={slide.id} data-hash={slide.id}>
                {slide['__typename'] === 'ContentfulSlideImage' && (
                  <ImageSlide
                    key={slide.id}
                    title={slide.title}
                    backgroundColor={slide.backgroundColor}
                    image={slide.image}
                    imageFit={slide.imageFit}
                  />
                )}
                {slide['__typename'] === 'ContentfulSlideVideo' && (
                  <VideoSlide
                    key={slide.id}
                    backgroundColor={slide.backgroundColor}
                    vimeoId={slide.vimeoId}
                    videoFit={slide.videoFit}
                    poster={slide.videoPoster}
                  />
                )}
              </SlideWrapper>
            ))}
          </CustomSwiper>
        </Slides>

        <ProgressColumn>
          {!isMobile && (
            <ProgressSlider
              slideCount={totalSlides}
              slideIndex={currentSlide}
              setSlideIndex={setCurrentSlide}
              swiper={swiper}
              // isMobile={isMobile}
            />
          )}
        </ProgressColumn>

        <BottomBar
          slides={slides}
          categoryTitle={categoryTitle}
          slideIndex={currentSlide}
          slideCount={totalSlides}
          setSlideIndex={setCurrentSlide}
          isHome={isHome}
          isMobile={isMobile}
          swiper={swiper}
        />
      </Grid>
    </>
  );
};

export default SlideView;
