/* eslint-disable no-confusing-arrow */
import React from 'react';
import Slider from './CustomSlider';

// components
import { ProgressNumber, MobileNumberDivider } from './styles';

const ProgressSlider = ({
  slideCount,
  slideIndex: currentIndex,
  setSlideIndex,
  swiper,
  isMobile,
}) => {
  const handleChange = ({ y }) => {
    if (swiper !== null) {
      swiper.slideTo(y);
    }
    if (y > slideCount) {
      // eslint-disable-next-line no-param-reassign
      y = slideCount;
    }
    setSlideIndex(y);
  };

  const formatSlideNumber = (index) =>
    index >= 0 && index < 10 ? `0${index}` : index;

  return (
    <>
      <ProgressNumber className="color-fade">
        {formatSlideNumber(currentIndex + 1)}
      </ProgressNumber>
      {isMobile && <MobileNumberDivider />}
      {!isMobile && (
        <Slider
          axis="y"
          y={currentIndex}
          ymax={slideCount - 1}
          ystep={1}
          onChange={handleChange}
          className="axis-y"
        />
      )}
      <ProgressNumber muted className="color-fade">
        {formatSlideNumber(slideCount)}
      </ProgressNumber>
    </>
  );
};

export default ProgressSlider;
