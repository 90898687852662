import React from 'react';

const PauseIcon = ({ fill, w, h }) => (
  <svg viewBox="0 0 24 24" width={w} height={h} id="video-control-pause">
    <path
      fill={fill}
      fillRule="nonzero"
      className="fill-fade"
      d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export default PauseIcon;
