import React from 'react';
import { Helmet } from 'react-helmet';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import KlefBookWoff2 from '../../../static/fonts/Book.woff2';
import KlefMediumWoff2 from '../../../static/fonts/Medium.woff2';
import KlefBoldWoff2 from '../../../static/fonts/Bold.woff2';

const Head = (props) => {
  const { title, description, canonical, openGraph, locale, keywords } = props;

  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }}>
        <link rel="preconnect" href="https://images.ctfassets.net/" />
        <link rel="preconnect" href="https://noembed.com/" />
        <link rel="preconnect" href="https://i.vimeocdn.com/" />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          href={KlefBookWoff2}
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          href={KlefMediumWoff2}
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          href={KlefBoldWoff2}
        />
      </Helmet>
      <GatsbySeo
        title={title}
        description={description}
        canonical={canonical}
        openGraph={openGraph}
        metaTags={[
          {
            name: 'keywords',
            content: (keywords || []).join(','),
          },
        ]}
      />
    </>
  );
};

export default Head;
