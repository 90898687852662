import React from 'react';
import { Link } from 'gatsby';

import { theme } from '../../styles';
// components
import { ShareButton } from '../ShareButton';
import { ProgressSlider } from '../ProgressSlider';
import { CategoryHeadline } from '../SlideView/styles';
import {
  FooterSection,
  SlideTitle,
  SlideSubtitle,
  HeadlineWrapper,
  SlideCategory,
  SlideCategoryWrapper,
  MobileSlideNumberWrapper,
  MobileCategoryWrapper,
  MobileHomeCategoryWrapper,
} from './styles';
import { ChevronIcon } from '../Svg';
// import { PlayerControl } from '../PlayerControl';

const BottomBar = ({
  slides,
  slideIndex: currentIndex,
  isHome,
  isMobile,
  slideCount: totalSlides,
  setSlideIndex: setCurrentSlide,
  swiper,
  categoryTitle,
}) => {
  const currentCategoryLink = isHome
    ? slides[currentIndex].category[0].page[0].slug
    : '';
  const currentCategoryTitle = isHome
    ? slides[currentIndex].category[0].title
    : slides[currentIndex].title;

  return (
    <FooterSection>
      {!isHome && (
        <>
          <HeadlineWrapper>
            <SlideTitle className="color-fade">
              {currentCategoryTitle}
            </SlideTitle>
            <SlideSubtitle className="color-fade">
              {(slides[currentIndex].subtitle || {}).subtitle}
            </SlideSubtitle>
            {isMobile && (
              <MobileCategoryWrapper>
                <CategoryHeadline>{categoryTitle}</CategoryHeadline>
                <ShareButton slide={slides[currentIndex]} />
              </MobileCategoryWrapper>
            )}
            {!isMobile && <ShareButton slide={slides[currentIndex]} />}
          </HeadlineWrapper>
          {isMobile && (
            <MobileSlideNumberWrapper>
              <ProgressSlider
                slideCount={totalSlides}
                slideIndex={currentIndex}
                setSlideIndex={setCurrentSlide}
                swiper={swiper}
                isMobile={isMobile}
              />
            </MobileSlideNumberWrapper>
          )}
        </>
      )}
      {
        isHome && (
          <>
            {!isMobile && (
              <Link to={`/${currentCategoryLink}`}>
                <SlideCategoryWrapper>
                  <SlideCategory className="color-fade">
                    Explore Category
                  </SlideCategory>
                  <ChevronIcon w="20px" h="20px" fill={theme.color.lightGrey} />
                </SlideCategoryWrapper>
              </Link>
            )}
            {isMobile && (
              <>
                <MobileHomeCategoryWrapper>
                  <CategoryHeadline isHome={isHome}>
                    {categoryTitle}
                  </CategoryHeadline>
                  <Link to={`/${currentCategoryLink}`}>
                    <SlideCategoryWrapper>
                      <SlideCategory className="color-fade">
                        Explore Category
                      </SlideCategory>
                      <ChevronIcon
                        w="20px"
                        h="20px"
                        fill={theme.color.lightGrey}
                      />
                    </SlideCategoryWrapper>
                  </Link>
                </MobileHomeCategoryWrapper>
                <MobileSlideNumberWrapper>
                  <ProgressSlider
                    slideCount={totalSlides}
                    slideIndex={currentIndex}
                    setSlideIndex={setCurrentSlide}
                    swiper={swiper}
                    isMobile={isMobile}
                  />
                </MobileSlideNumberWrapper>
              </>
            )}
          </>
        )
        /* {slides &&
        // eslint-disable-next-line no-underscore-dangle
        slides[currentIndex].__typename === 'ContentfulSlideVideo' &&
        slides[currentIndex].videoControls && <PlayerControl />} */
      }
    </FooterSection>
  );
};
export default BottomBar;
