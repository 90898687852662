const theme = {
  color: {
    white: '#FFF',
    black: '#000',
    darkGrey: '#222',
    midGrey: '#5A5A5A',
    lightGrey: '#A3A3A3',
    ultraLightGrey: '#D5D5D5',
  },

  backgroundColors: {
    dark: '#000',
    light: '#FFF',
    grey: '#F2F2F2',
  },

  font: {
    family: 'Roschlaub',
    display: 'auto',

    size: {
      base: '10px',
      h1: '5rem',
      h2: '3rem',
      h3: '2rem',
      h4: '1.8rem',
      h5: '1.125em',
      h6: '1.125em',
      p: '1.125rem',
      slideCategory: '1.8rem',
      slideTitle: '1.3rem',
      slideSubtitle: '1rem',
    },

    weight: {
      bold: 600,
      medium: 500,
      book: 300,
    },
  },

  easings: {
    themeChange: '500ms ease-in-out',
    animatedBorder: 'cubic-bezier(0.86, 0, 0.07, 1)',
  },

  breakpoints: {
    xxsMax: '320px',
    xs: '376px',
    xsMax: '767px',
    sm: '768px',
    smMax: '970px',
    md: '971px',
    lrg: '1200px',
  },
};

export default theme;

export const lightTheme = {
  ...theme,
  color: {
    foreground: '#000',
    background: '#FFF',
    accentStrong: '#222',
    accentMid: '#A3A3A3',
    accentLight: '#D5D5D5',
    hover: '#E6E6E6',
  },

  backgroundColors: {
    dark: '#000',
    light: '#FFF',
    grey: '#F2F2F2',
  },
};

export const darkTheme = {
  ...theme,
  color: {
    foreground: '#FFF',
    background: '#000',
    accentStrong: '#FFF',
    accentMid: '#A3A3A3',
    accentLight: '#D5D5D5',
    hover: 'rgba(163, 163, 163, 0.3)',
  },

  backgroundColors: {
    dark: '#000',
    light: '#FFF',
    grey: '#F2F2F2',
  },
};
