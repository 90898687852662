import styled from 'styled-components';

export const Main = styled.main`
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  position: fixed;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.background};
  color: ${({ theme }) => theme.color.foreground};

  &.rte-text {
    height: auto;
    overflow: scroll;
    position: initial;
  }
`;

export const BackgroundImage = styled.figure`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 0;
  background-image: ${({ url }) => `url(${url}&fl=progressive)`};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;
