import React, { createContext, useContext, useReducer } from 'react';

export const SwiperStateContext = createContext();

const initialState = {
  videoPlaying: true,
  videoFullscreen: false,
  videoReady: false,
  videoMuted: false,
  videoPlayedSeconds: 0,
  videoPlayed: 0,
  videoSeeking: false,
  currentPlayer: null,
  currentSwiper: null,
  currentTheme: 'light',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_THEME':
      return { ...state, currentTheme: action.value };
    case 'TOGGLE_VIDEO_PLAYBACK':
      return { ...state, videoPlaying: !state.videoPlaying };
    case 'TOGGLE_VIDEO_FULLSCREEN':
      return { ...state, videoFullscreen: action.value };
    case 'SET_VIDEO_READY':
      return { ...state, videoReady: action.value };
    case 'SET_CURRENT_PLAYER':
      return { ...state, currentPlayer: action.value };
    case 'SET_CURRENT_SWIPER':
      return { ...state, currentSwiper: action.value };
    case 'SET_VIDEO_PROGRESS':
      if (action.onlyPlayed) {
        return { ...state, videoPlayed: action.value };
      }
      return {
        ...state,
        videoPlayed: action.value.played,
        videoPlayedSeconds: action.value.playedSeconds,
      };
    case 'SET_VIDEO_DURATION':
      return { ...state, videoDuration: action.value };
    case 'SET_VIDEO_SEEKING':
      return { ...state, videoSeeking: action.value };
    case 'TOGGLE_VIDEO_AUDIO':
      return { ...state, videoMuted: !state.videoMuted };
    case 'RESET_VIDEO':
      return {
        ...state,
        currentPlayer: null,
        videoPlaying: false,
        videoPlayedSeconds: 0,
        videoPlayed: 0,
      };
    default:
      return state;
  }
};

export const SwiperStateProvider = ({ children }) => (
  <SwiperStateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </SwiperStateContext.Provider>
);

export const useSwiperState = () => useContext(SwiperStateContext);
