import styled from 'styled-components';

export const IconButton = styled.button`
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  margin: 0 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.color.hover};
    border-radius: 50%;
    svg path {
      fill: ${({ theme }) => theme.color.accentStrong};
      transition: fill 50ms ease-in-out;
      will-change: fill;
    }
  }
  &:focus {
    outline: none;
  }
`;
