import { useEffect, useState, useCallback } from 'react';

/**
 * Toggles on viewport size change by given media query
 *
 * @param {array} queries Media queries
 * @param {array} values Returning values if corresponding media query is active
 * @param {*} defaultValue Returned if no media query fits
 * @returns {*} Active values
 *
 * @example
 *   const active = useMediaQuery([(min-width: 680px)], [true], false)
 */
const useMediaQuery = (queries, values, defaultValue) => {
  // Array containing a media query list for each query
  // eslint-disable-next-line operator-linebreak
  const mediaQueryLists =
    typeof window !== 'undefined' && window.matchMedia
      ? queries.map(q => window.matchMedia(q))
      : [];

  // Function that gets value based on matching media query
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getValue = useCallback(() => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    // Return related value or defaultValue if none
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  });

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setValue(getValue);
      // Set a listener for each media query with above handler as callback.
      mediaQueryLists.forEach(mql => mql.addListener(handler));
      // Remove listeners on cleanup
      return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
    },
    // eslint-disable-next-line comma-dangle
    [getValue, mediaQueryLists] // Empty array ensures effect is only run on mount and unmount
  );

  return value;
};

export default useMediaQuery;
