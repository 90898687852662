import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router';
import { copyToClipboard } from '../../helpers/clipboard';
// components
import { ShareIcon } from '../Svg';
import { theme } from '../../styles';
import { IconButton } from './styles';

const ShareButton = ({ slide }) => {
  const { id, title, subtitle } = slide;
  const loc = useLocation();

  const [message, setMessage] = useState(null);

  const autoFadeMessage = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage(null);
    }, 3000);
  };

  const handleShare = async () => {
    if (!loc) return;
    const currentBaseUrl = loc.origin + loc.pathname;
    const shareUrl = `${currentBaseUrl}?id=${id}`;

    if (navigator.share) {
      const shareData = {
        title: `${title} | Photo by Holger Roschlaub`,
        text: (subtitle || {}).subtitle || '',
        url: shareUrl,
      };
      try {
        await navigator.share(shareData);
        autoFadeMessage('Link successfully shared.');
      } catch (err) {
        if (err.name === 'AbortError') {
          autoFadeMessage('Sharing cancelled.');
          return;
        }
        // eslint-disable-next-line no-console
        console.error(err);
        autoFadeMessage('Something went wrong.');
      }
    } else if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(shareUrl);
        autoFadeMessage('Link copied to clipboard.');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        autoFadeMessage('Something went wrong.');
      }
    } else if (document.execCommand) {
      try {
        copyToClipboard(shareUrl);
        autoFadeMessage('Link copied to clipboard.');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        autoFadeMessage('Something went wrong.');
      }
    } else {
      autoFadeMessage('Something went wrong.');
    }
  };

  return (
    <>
      <IconButton
        role="button"
        title="Copy Link"
        aria-label="Copy Link"
        onClick={() => handleShare()}
      >
        <ShareIcon fill={theme.color.lightGrey} />
      </IconButton>
      {message && <span>{message}</span>}
    </>
  );
};
export default ShareButton;
